import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Header from './Header';
const MovieDetails = () => {
    const { title, year } = useParams();
    const [movieDetails, setMovieDetails] = useState(null);
    const [currentRating, setCurrentRating] = useState(null);

    useEffect(() => {
        const getMovieDetails = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get(
                    `https://filmatcher.com/api/get_movie_details?movie_name=${encodeURIComponent(title)}&movie_year=${encodeURIComponent(year)}`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                console.log('Response:', response.data);
                setMovieDetails(response.data[0]); // Access the first element of the array
               
                console.log('Movie details:', movieDetails);
                setCurrentRating(response.data[0].wordRating || 'None');
            } catch (error) {
                console.error('Error fetching movie details:', error);
            }
        };

        getMovieDetails();
    }, [title, year]);

    const handleRating = async (rating) => {
        setCurrentRating(rating);
        console.log('Rating:', rating, 'Movie ID:', movieDetails.movieID);
        if (movieDetails) {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.post(
                    `https://filmatcher.com/api/add_review?movieID=${movieDetails.movieID}&wordRating=${rating}&numberRating=1`,
                    {},
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );  
                console.log(response.data);
            } catch (error) {
                console.error(error);
                setCurrentRating(movieDetails.wordRating);
            }
        }
    };

    if (!movieDetails) {
        return <div>Loading...</div>;
    }

    return (
        <>        
        <Header />
        <div className="relative">
            <img src={movieDetails.image_url} alt={movieDetails.name} className="w-full h-screen object-cover" />
            <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-70 text-white p-5">
                <h1 className="text-6xl">{movieDetails.name}</h1>
                <p className="text-4xl">{movieDetails.year}</p>
                <p className="text-3xl">{movieDetails.runtime}</p>
                <div className="flex justify-center items-center mt-4">
                    <button 
                        onClick={() => handleRating('loved')} 
                        className={`text-white rounded-full w-12 h-12 flex items-center justify-center hover:opacity-100 active:bg-pink-700 mr-2 ${currentRating === 'loved' ? 'bg-pink-300 transform scale-125 opacity-100' : 'bg-pink-500 opacity-50'}`}
                    >
                        ❤️
                    </button>
                    <button 
                        onClick={() => handleRating('good')} 
                        className={`text-white rounded-full w-12 h-12 flex items-center justify-center hover:opacity-100 active:bg-green-700 mr-2 ${currentRating === 'good' ? 'bg-green-300 transform scale-125 opacity-100' : 'bg-green-500 opacity-50'}`}
                    >
                        👍
                    </button>
                    <button 
                        onClick={() => handleRating('bad')} 
                        className={`text-white rounded-full w-12 h-12 flex items-center justify-center hover:opacity-100 active:bg-red-700 mr-2 ${currentRating === 'bad' ? 'bg-red-300 transform scale-125 opacity-100' : 'bg-red-500 opacity-50'}`}
                    >
                        👎
                    </button>
                    <button 
                        onClick={() => handleRating('unseen')} 
                        className={`text-white rounded-full w-12 h-12 flex items-center justify-center hover:opacity-100 active:bg-blue-700 ${currentRating === 'unseen' ? 'bg-blue-300 transform scale-125 opacity-100' : 'bg-blue-500 opacity-50'}`}
                    >
                        ❓
                    </button>
                </div>
            </div>
        </div>
        </>
    );
};

export default MovieDetails;